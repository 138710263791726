// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutus-tsx": () => import("./../../../src/pages/aboutus.tsx" /* webpackChunkName: "component---src-pages-aboutus-tsx" */),
  "component---src-pages-howtoplay-tsx": () => import("./../../../src/pages/howtoplay.tsx" /* webpackChunkName: "component---src-pages-howtoplay-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legalnotice-tsx": () => import("./../../../src/pages/legalnotice.tsx" /* webpackChunkName: "component---src-pages-legalnotice-tsx" */),
  "component---src-pages-morethanrussianstandard-tsx": () => import("./../../../src/pages/morethanrussianstandard.tsx" /* webpackChunkName: "component---src-pages-morethanrussianstandard-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

