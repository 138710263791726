import './src/css/style.scss';
import './src/css/vendor.scss';

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log('load intersection polyfill')
  }
};
